<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-company-apitoken-edit" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <label for="Description" class="form-label required"
                >{{
                  $t(
                    "ApiToken.Description",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Description"
                v-model="apiTokenData.description"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="User" class="form-label required"
                >{{
                  $t(
                    "ApiToken.UserName",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <FormSelect
                type="SelectWithRemoteDataAndInternalSearch"
                @onChange="onChangeUser"
                :isDetailIconShow="true"
                :routeLink="String.format('#/User/Edit/')"
                :selectedData="selectedUser"
                :allowEmpty="false"
                requestUrl="/Lcdp-SummaryApiUsers"
              />
            </div>
            <div class="col-12">
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "BaseModelFields.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="apiTokenData.isActive"
                />
              </div>
            </div>
            <ActionButtons
              @methodSave="onSubmit('btn-save')"
              :isNewRecord="false"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "ApiTokenEdit",
  components: {},
  data() {
    return {
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save",
      errors: [],
      apiTokenData: [],
      selectedUser: [],
    };
  },
  methods: {
    getApiTokenDetail() {
      var self = this;
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-CompanyApiTokenGet?id={0}",
            this.$route.params.apiTokenId
          )
        )
        .then((response) => {
          this.apiTokenData = response.data;
          if (!String.isNullOrWhiteSpace(response.data.userPublicId)) {
            self.selectedUser.push({
              key: response.data.userPublicId.toString(),
              value: response.data.userName,
            });
            self.apiTokenData.UserPublicId = response.data.userPublicId;
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    onChangeUser(selected) {
      this.apiTokenData.userPublicId = selected.key;
    },
    onSubmit(buttonName) {
      var form = $(".form-company-apitoken-edit");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-CompanyApiTokenEdit", { ...this.apiTokenData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.$router.push("/ApiToken/List");
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
  mounted() {
    this.getApiTokenDetail();
  },
};
</script>
